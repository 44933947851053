<template>
  <div survey-modal>
    <div class="is-size-4" v-if="formData">{{ buttonText }} Survey</div>
    <ValidationObserver ref="formGroup" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(submitSurvey)">
          <fieldset>
            <div class="form-grid">
              <div class="column is-12">
                <div class="column is-12">
                  <label class="label"
                    >Start Date<Info
                      title="Start Date"
                      information="Represents the date when the survey has started."
                  /></label>
                  <DatePicker
                    v-model="formData.availableStartDate"
                    ariaLabel="Start Date"
                    ref="availableStartDateComponent"
                  />
                </div>
                <div class="column is-12">
                  <label class="label"
                    >End Date<Info
                      title="End Date"
                      information="Represents the date when the survey has ended."
                  /></label>
                  <DatePicker
                    v-model="formData.availableEndDate"
                    ariaLabel="End Date"
                    ref="availableEndDateComponent"
                  />
                </div>
                <div
                  v-if="surveyTypes && surveyTypes != undefined"
                  class="column is-12"
                  style="padding:10px; white-space:nowrap;"
                >
                  <label class="label"
                    >Survey Type<Info
                      title="Title"
                      information="The type of survey. A 'Simple' survey will have one section. A 'Multiple' can have many sections."
                  /></label>
                  <valid-select
                    placeholder="Survey Type"
                    vid="surveyType"
                    v-model="formData.surveyType"
                    aria-required="The survey type is required"
                    rules="required"
                  >
                    <option
                      v-for="option in surveyTypes"
                      :value="option.value"
                      :key="option.value"
                      >{{ option.label }}</option
                    >
                  </valid-select>
                </div>
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <label class="label"
                    >Name<Info title="Name" information="The Name of the survey."
                  /></label>
                  <valid-input
                    v-model="formData.name"
                    name="name"
                    type="text"
                    vid="name"
                    placeholder="Name"
                    rules="required|max:100"
                  />
                </div>
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <label class="label"
                    >Header<Info
                      title="Name"
                      information="Short description at the top of the survey."
                  /></label>
                  <valid-input
                    v-model="formData.header"
                    name="header"
                    type="text"
                    vid="header"
                    placeholder="Header"
                    rules="required|max:100"
                  />
                </div>
                <div class="column is-12">
                  <label class="label"
                    >Instructions<Info
                      title="Name"
                      information="Instructions for filling the survey out."
                  /></label>
                  <valid-input
                    style="width:50px !important;"
                    name="Instructions"
                    maxlength="8000"
                    type="textarea"
                    vid="Instructions"
                    placeholder="Instructions"
                    spellcheck="true"
                    aria-label="Instructions"
                    rules="max:8000|required"
                    class="is-small"
                    v-model="formData.instructions"
                  />
                </div>
                <div class="pt-5">
                  <button
                    type="submit"
                    tests-id="survey-modal-button"
                    :disabled="loading"
                    class="button is-primary is-rounded"
                  >
                    {{ buttonText }}
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import Info from '@/components/icons/Info'
import ValidInput from '@/components/inputs/ValidInput'
import DatePicker from '@/components/inputs/DatePicker'
import ValidSelect from '@/components/inputs/ValidSelect'

export default {
  props: {
    survey: Object
  },

  components: {
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider,
    DatePicker,
    Info
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
