import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  selectedSurvey: null,
  toggle: false,
  surveys: [],
  newID: 0,
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: false
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'startDate',
      label: 'Start Date',
      aria: 'Start Date',
      width: '10%',
      date: true,
      sortable: true,
      searchable: true
    },
    {
      field: 'endDate',
      label: 'End Date',
      aria: 'End Date',
      width: '10%',
      date: true,
      sortable: true,
      searchable: true
    },
    {
      field: 'name',
      label: 'Name',
      aria: 'Name',
      width: '40%',
      sortable: true,
      searchable: true
    },
    {
      field: 'header',
      label: 'Header',
      aria: 'Header',
      width: '40%',
      sortable: true,
      searchable: true
    }
  ],
  styles: {
    datePickerContainer: { display: 'flex', alignItems: 'end' },
    datePicker: { margin: 0, marginRight: '12px' }
  }
})
