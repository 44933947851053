/*
  import { surveyItem } from '@/services/Survey/SurveyModal/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  // Update Survey
  async updateSurvey({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/Surveys/SurveyHeader`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.surveyHeaderID > 0) {
        notifyMessage(`Successfully updated the survey.`)
        done()
      } else {
        notifyError('There was a problem updating this survey.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for this survey.`)
      console.error(exception)
    }
  },

  // Add Survey
  async addSurvey(
    { dispatch },
    { hoaID, name, header, instructions, surveyType, availableStartDate, availableEndDate, done }
  ) {
    if (isDebug == true) console.debug('...in addSurvey')
    try {
      const results = await kms.post(`/Surveys/SurveyHeader`, {
        hoaID,
        name,
        header,
        instructions,
        surveyType,
        availableStartDate,
        availableEndDate
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.surveyHeaderID > 0) {
        notifyMessage(`Successfully added the survey.`)
        done({ details: results })
      } else {
        notifyError('There was a problem adding this survey.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this survey.`)
      console.error(exception)
    }
  },

  async getSurveyTypeList({}) {
    try {
      let dropdownSurveyTypes = [
        { value: 'simple', label: 'Simple - (single section)' },
        { value: 'multiple', label: 'Multiple - (multiple sections)' }
      ]

      if (isDebug == true)
        console.debug('dropdownSurveyTypes=' + JSON.stringify(dropdownSurveyTypes))

      return {
        list: dropdownSurveyTypes
      }
    } catch (exception) {
      notifyProblem(`The survey type list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const surveyItem = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
