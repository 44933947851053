export function data() {
  return {
    formData: {
      surveyHeaderID: 0,
      availableStartDate: '',
      availableEndDate: '',
      name: '',
      header: '',
      instructions: '',
      surveyType: ''
    },
    surveyTypes: [],
    isInfo1Active: false,
    isDebug: true,
    isOpen: false,
    loading: true,
    isFetching: false,
    openOnFocus: true,
    buttonText: 'Add'
  }
}
