import _get from 'lodash/get'
import moment from 'moment'
import { surveyList } from '@/services/Surveys/store'
import Button from '@/components/buttons/Button'

export const methods = {
  determineRows: function(list) {
    if (!Array.isArray(list)) {
      return
    }

    var rows = list.map(entry => {
      const dateFormat = 'LL'

      const name = _get(entry, 'name', '')
      const header = _get(entry, 'header', '')
      const id = _get(entry, 'surveyHeaderID', 0)

      var date = _get(entry, 'availableStartDate', '')

      try {
        date = moment(date).format(dateFormat)
      } catch (exception) {
        console.error(exception)
      }

      var endDate = _get(entry, 'availableEndDate', '')
      let endDateMoment = endDate

      try {
        endDateMoment = moment(endDate).format(dateFormat)
      } catch (exception) {
        console.error(exception)
      }

      return {
        startDate: {
          date,
          format: dateFormat
        },
        endDate: {
          date: endDateMoment,
          format: dateFormat
        },
        name: name,
        header: header,
        edit: {
          component: Button,
          props: {
            onClick: () => {
              this.processSurveyHeaderUpdate(entry)
            },
            text: 'Edit'
          }
        },
        erase: {
          component: Button,
          props: {
            onClick: () => {
              this.confirmDeleteSurveyHeader(id, name)
            },
            text: 'Delete'
          }
        },
        details: {
          component: Button,
          props: {
            onClick: () => {
              this.processDetail(id, name)
            },
            text: 'Details'
          }
        },
        results: {
          component: Button,
          props: {
            onClick: () => {
              this.processResults(id, name)
            },
            text: 'Results'
          }
        }
      }
    })

    this.rows = rows
  },

  createSurveyModal() {
    this.selectedSurvey = null
    this.toggle = true
  },

  redirectToNewSurveySection() {
    this.reload().then(() => {
      console.debug('this.surveys=' + JSON.stringify(this.surveys))

      let maxId = 0
      if (this.surveys) {
        Array.from(this.surveys).forEach(f => {
          if (f) {
            if (f.surveyHeaderID > maxId) {
              maxId = f.surveyHeaderID
            }
          }
        })
      }

      if (this.isDebug == true) console.debug('max=' + maxId)

      const justAdded = this.surveys.find(f => maxId == f.surveyHeaderID)
      if (this.isDebug == true) console.debug('justAdded=' + JSON.stringify(justAdded))

      if (justAdded) {
        this.processDetail(justAdded.surveyHeaderID, justAdded.name)
      }
    })
  },

  confirmDeleteSurveyHeader(id, name) {
    this.$buefy.dialog.confirm({
      title: 'Deleting Survey',
      message: `Are you sure you want to <b>delete</b> the survey for '${name}'?`,
      confirmText: 'Delete Survey',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteSurveyHeader(id)
    })
  },

  processSurveyHeaderUpdate(surveyHeader) {
    this.toggle = true
    this.selectedSurvey = surveyHeader
  },

  async deleteSurveyHeader(id) {
    this.selectedSurvey = null
    await surveyList.dispatch('deleteSurvey', {
      surveyHeaderID: id,
      done: () => {
        this.loading = false
        this.reload()
      }
    })
  },

  async loadSurvey() {
    if (this.isDebug == true) console.debug('in loadSurvey()...')
    await surveyList
      .dispatch('getSurveyList', {
        hoaId: this.hoaId
      })
      .then(({ list }) => {
        this.surveys = list
        this.determineRows(list)
      })
  },

  processDetail(surveyHeaderID, name) {
    this.selectedSurvey = null

    console.debug('params=' + surveyHeaderID + ' name=' + JSON.stringify(name))

    if (surveyHeaderID) {
      this.$router.push({
        name: 'surveySections',
        params: {
          id: surveyHeaderID,
          name: name
        }
      })
    }
  },

  processResults(surveyHeaderID, name) {
    this.selectedSurvey = null

    console.debug('params=' + surveyHeaderID + ' name=' + JSON.stringify(name))

    if (surveyHeaderID) {
      this.$router.push({
        name: 'surveyResults',
        params: {
          id: surveyHeaderID,
          name: name
        }
      })
    }
  },

  async reload() {
    this.loading = true

    this.rows = []

    await this.loadSurvey()

    this.loading = false
  }
}
