/*
  import { surveyList } from '@/services/Surveys/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import kms from '@/services/kms'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import _get from 'lodash/get'
const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await surveyList.dispatch ('getSurveyList', {
      hoaId
    });
  */
  async getSurveyList({}, { hoaId }) {
    console.log('in getSurveyList...')
    try {
      const result = await kms.get('/Surveys/SurveyHeader/List', {
        params: {
          hoaId
        }
      })

      if (isDebug == true) console.debug('getSurveyList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The surveys list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  //Survey by Id
  async getSurveyById({}, { surveyID }) {
    try {
      console.debug('in getSurveyById...' + surveyID)
      const survey = await kms.get(`/Surveys/SurveyHeader/${surveyID}`)

      if (isDebug == true) console.debug('getSurveyById=' + JSON.stringify(survey))

      return {
        result: survey
      }
    } catch (exception) {
      notifyProblem(`There was a problem retrieving the survey.`)
      console.error(exception)
    }

    return {
      survey: {}
    }
  },

  async deleteSurvey({ dispatch }, { surveyHeaderID, done }) {
    try {
      const path = `/Surveys/SurveyHeader/${surveyHeaderID}?forceDelete=false`

      const results = await kms.delete(path)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted the survey.`)
        done()
      } else {
        notifyError('There was a problem deleting the survey.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting this survey.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const surveyList = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
