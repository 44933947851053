import { surveyItem } from '@/services/Surveys/SurveyModal/store'

import $ from 'jquery'
import { notifyError } from '@/services/notify'
import _get from 'lodash/get'
import moment from 'moment'

export const methods = {
  async reload() {
    this.loading = true

    const today = moment()

    await this.loadSurveyTypes()

    console.debug('this.survey=' + JSON.stringify(this.survey))

    if (this.survey && this.survey != undefined) {
      //Update
      this.buttonText = 'Save'

      this.formData.surveyHeaderID = this.survey.surveyHeaderID
      this.formData.name = this.survey.name
      this.formData.header = this.survey.header
      this.formData.instructions = this.survey.instructions
      this.formData.surveyType = this.survey.surveyType

      var availableStartDate = this.survey.availableStartDate
      if (availableStartDate) {
        this.$refs.availableStartDateComponent.selected = new Date(this.survey.availableStartDate)
      }

      var availableEndDate = this.survey.availableEndDate
      if (availableEndDate) {
        this.$refs.availableEndDateComponent.selected = new Date(this.survey.availableEndDate)
      }

      this.loading = false
    } else {
      this.buttonText = 'Add'
      this.$refs.availableStartDateComponent.selected = new Date(today)
      this.$refs.availableEndDateComponent.selected = new Date(today)
      this.loading = false
    }

    this.loading = false
  },

  open() {
    this.formData = {
      surveyHeaderID: 0,
      availableStartDate: '',
      availableEndDate: '',
      name: '',
      header: '',
      instructions: ''
    }

    this.loading = false
  },

  async loadSurveyTypes() {
    await surveyItem.dispatch('getSurveyTypeList').then(t => {
      if (t && t.list && t.list != undefined) {
        console.log('list=' + JSON.stringify(t.list))
        this.surveyTypes = t.list
      }
    })
  },

  async submitSurvey() {
    if (this.formData && this.formData != undefined) {
      if (this.formData.surveyHeaderID && this.formData.surveyHeaderID > 0) {
        await this.updateSurveyHeader()
      } else {
        await this.addSurveyHeader()
      }
    }
  },

  async addSurveyHeader() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem processing this survey header.')
      return
    }

    if (!this.formData.availableStartDate || this.formData.availableStartDate == undefined) {
      notifyError('The available start date is required.')
      return
    }

    if (!this.formData.availableEndDate || this.formData.availableEndDate == undefined) {
      notifyError('The available end date is required.')
      return
    }

    this.loading = true
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    await surveyItem.dispatch('addSurvey', {
      hoaID,
      name: this.formData.name ? this.formData.name : '',
      header: this.formData.header ? this.formData.header : '',
      instructions: this.formData.instructions ? this.formData.instructions : '',
      surveyType: this.formData.surveyType || 'multiple',
      availableStartDate: this.formData.availableStartDate,
      availableEndDate: this.formData.availableEndDate,
      done: ({ details }) => {
        // auto-close modal
        if (details) {
          if (this.isDebug == true) console.debug('details=' + JSON.stringify(details))
          this.newID = details.surveyHeaderID
        }

        this.$emit('update:newID', (this.newID = details.surveyHeaderID))
        this.$emit('update:toggle', (this.toggle = false))
        $('#clickclose').click()
        this.loading = false
      }
    })
  },

  async updateSurveyHeader() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem updating this survey.')
      return
    }

    if (!this.formData.availableStartDate || this.formData.availableStartDate == undefined) {
      notifyError('The available start date is required.')
      return
    }

    if (!this.formData.availableEndDate || this.formData.availableEndDate == undefined) {
      notifyError('The available end date is required.')
      return
    }

    if (this.survey.surveyType == 'multiple' && this.formData.surveyType == 'simple') {
      this.confirmSurveyTypeChange()
      return
    }

    this.updatePayload()
  },

  confirmSurveyTypeChange() {
    this.$buefy.dialog.confirm({
      title: 'Survey Type Change',
      message: `Changing the survey type from a multiple to simple type, might delete your existing sections and options. Are you sure you want to <b>proceed</b> with this change'?`,
      confirmText: 'Change Survey Type',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.updatePayload()
    })
  },

  async updatePayload() {
    this.loading = true
    const hoaID = _get(this, ['$store', 'getters', 'user/hoaIDInteger'], null)

    const payload = {
      surveyHeaderID: this.formData.surveyHeaderID,
      hoaID: hoaID,
      name: this.formData.name ? this.formData.name : '',
      header: this.formData.header ? this.formData.header : '',
      instructions: this.formData.instructions ? this.formData.instructions : '',
      surveyType: this.formData.surveyType || 'multiple',
      availableStartDate: this.formData.availableStartDate,
      availableEndDate: this.formData.availableEndDate
    }

    await surveyItem.dispatch('updateSurvey', {
      payload: payload,
      done: () => {
        // auto-close modal
        this.$emit('update:toggle', (this.toggle = false))
        $('#clickclose').click()
        this.loading = false
      }
    })
  }
}
