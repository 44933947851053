<template>
  <PageContentLayoutOnly role="region">
    <div class="level-left">
      <b-button
        tests-id="add-survey-button"
        @click.prevent="createSurveyModal()"
        size="is-small"
        type="is-primary"
        rounded
      >
        Add Survey
      </b-button>
    </div>

    <ModernTable
      :region="region"
      :filters="filters"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
      headerColor="#FFF"
      condensed
    >
      <template v-slot:header>
        <div :style="{ width: '100%', height: '60px' }">
          <b-button
            type="is-ghost"
            @click="filters.show = !filters.show"
            :style="{ float: 'right', background: 'none', border: 0 }"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
      </template>
    </ModernTable>
    <Modal :toggle.sync="toggle" :newID.sync="newID">
      <SurveyModal :survey="selectedSurvey" />
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import { methods } from './keys/methods'
import { data } from './keys/data'
import SurveyModal from './component/SurveyModal'
import Modal from '@/components/Modal'

export default {
  name: 'Surveys',
  components: {
    PageContentLayoutOnly,
    ModernTable,
    SurveyModal,
    Modal
  },

  data,

  methods,

  computed: {
    ...mapState({
      unit: state => state.hoa.unit,
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser', 'retrieveUser'])
    })
  },

  watch: {
    toggle() {
      if (this.toggle == false) {
        this.toggle = false

        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }

        if (this.selectedSurvey == null) {
          //redirect to section
          this.redirectToNewSurveySection()
        } else {
          this.reload()
        }
      }
    }
  },

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { survey: { title: 'Community Info' } }
    }
  }
}
</script>

<style></style>
